<template>
  <div>
    <a-form :form="form" @submit="handleSubmit">
      <a-row :gutter="20">
        <a-col :span="12">
          <HayaTextField
            label="Nome da etapa"
            placeholder="Escreva"
            fieldKey="name"
            :form="form"
            :required="true"
            :preIcon="true"
          />
        </a-col>

        <a-col :span="12">
          <HayaSelectField
            label="Responsável pela etapa"
            optionFilterProp="txt"
            fieldKey="step_user_id"
            :form="form"
            :list="
              mapUserList(
                user.list.concat({
                  first_name: 'Ninguém',
                  last_name: '',
                  id: '0',
                })
              )
            "
            :allowClear="true"
            :showSearch="true"
          />
        </a-col>

        <a-col :span="24">
          <a-form-item class="travel-input-outer">
            <label :class="'filled'" style="top: -46px">Descrição</label>
            <a-input
              class="travel-textarea f13"
              placeholder="Escreva uma  descrição para o funil"
              type="textarea"
              v-decorator="[`description`]"
            >
            </a-input>
          </a-form-item>
        </a-col>

        <a-col :span="18">
          <HayaTextField
            label="Hash da etapa (integração)"
            fieldClass="readonly"
            placeholder="Escreva"
            fieldKey="hash"
            :form="form"
            :preIcon="true"
          />
        </a-col>

        <a-col :span="6">
          <HayaSelectField
            :form="form"
            label="Status"
            fieldKey="status"
            :required="true"
            :list="status"
            :allowClear="true"
            :showSearch="true"
          />
        </a-col>

        <a-col :span="12">
          <a-form-item>
            <a-checkbox
              class="f13"
              :checked="
                form.getFieldValue(`allow_contract`) == 1 ? true : false
              "
              v-decorator="[`allow_contract`]"
              >Permitir gerar contrato</a-checkbox
            >
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item>
            <a-checkbox
              class="f13"
              :checked="
                form.getFieldValue(`allow_create_lead`) == 1 ? true : false
              "
              v-decorator="[`allow_create_lead`]"
              >Permitir criar oportunidade</a-checkbox
            >
          </a-form-item>
        </a-col>

        <a-col class="mt-10" :span="12">
          <a-form-item class="travel-input-outer">
            <label :class="'filled'"> Cor da etapa </label>
            <div class="form__input">
              <VSwatches
                :swatches="swatches"
                v-model="funnelStepColor"
                show-fallback
                popover-x="left"
              ></VSwatches>
            </div>
          </a-form-item>
        </a-col>

        <a-col :span="6">
          <a-form-item class="travel-input-outer">
            <label :class="'filled'">Probabilidade de ganho </label>
            <a-input-number
              placeholder="Insira"
              v-decorator="[
                `probability_winning_percentage`,
                {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
              :min="0"
              :max="100"
            />
            <span class="f12" style="width: 42px; display: inline-block"
              ><b>%</b>
            </span>
          </a-form-item>
        </a-col>

        <a-col :span="24">
          <a-divider class="mt-0" />
          <h3 class="f13 mb-20">Estagnação da oportunidade</h3>
          <a-row :gutter="20">
            <a-col class="mb-10" :span="24">
              <a-form-item>
                <a-checkbox
                  class="f13"
                  :checked="
                    form.getFieldValue(`stagnation_status`) == 1 ? true : false
                  "
                  v-decorator="[`stagnation_status`]"
                  >Ativar estagnação das oportunidades</a-checkbox
                >
              </a-form-item>
            </a-col>
            <a-col
              v-show="
                ['1', true].includes(form.getFieldValue(`stagnation_status`))
              "
              :span="12"
            >
              <HayaSelectField
                :form="form"
                label="Responsável da estagnação"
                description="O usuário que receberá as oportunidades estaganadas."
                fieldKey="stagnation_user_id"
                :list="
                  mapUserList(
                    user.list.concat({
                      first_name: 'Ninguém',
                      last_name: '',
                      id: '0',
                    })
                  )
                "
                :allowClear="true"
                :showSearch="true"
              />
            </a-col>
            <a-col
              v-show="
                ['1', true].includes(form.getFieldValue(`stagnation_status`))
              "
              :span="12"
            >
              <HayaSelectField
                :form="form"
                label="Etapa de direcionameto"
                description="Etapa onde a oportunidade será direcionada após a estagnação."
                fieldKey="stagnation_step"
                :list="mapStepsList(opportunitiesFunnelSteps.list)"
                :allowClear="true"
                :showSearch="true"
              />
            </a-col>

            <a-col
              v-show="
                ['1', true].includes(form.getFieldValue(`stagnation_status`))
              "
              :span="6"
            >
              <a-form-item class="travel-input-outer">
                <label :class="'filled'"> Dias para estagnação </label>
                <a-input-number
                  placeholder="Insira"
                  v-decorator="[
                    `stagnation_alert_days`,
                    {
                      rules: [
                        {
                          required: false,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]"
                  :min="0"
                  :max="100"
                />
                <span
                  class="f12 relative"
                  style="width: 42px; display: inline-block; top: 5px"
                  ><b>dias</b>
                </span>
              </a-form-item>
            </a-col>
          </a-row>
          <a-divider class="mt-0" />
        </a-col>

        <a-col class="a-center" :span="24">
          <a-button
            type="primary"
            html-type="submit"
            :loading="loadingUpdateFunnelStep"
          >
            Atualizar etapa
          </a-button>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>

<script>
import VSwatches from "vue-swatches";
import "vue-swatches/dist/vue-swatches.css";

import userMixins from "@/mixins/user/userMixins.js";
import opportunitiesFunnelStepsMixins from "../mixins/opportunitiesFunnelStepsMixins";
import HayaSelectField from "@/components/general/fields/HayaSelectField.vue";
import HayaTextField from "@/components/general/fields/HayaTextField.vue";

export default {
  name: "EditFunnelStepModal",
  props: {
    editId: String,
    funnelId: String,
  },
  components: { HayaSelectField, HayaTextField, VSwatches },
  mixins: [userMixins, opportunitiesFunnelStepsMixins],
  data() {
    return {
      form: this.$form.createForm(this),
      loadingUpdateFunnelStep: false,
      funnelStepColor: "#FFFFFF",
      noneOption: [
        {
          id: "0",
          first_name: "Ninguém",
        },
      ],
      status: [
        {
          label: "Ativado",
          value: "Ativado",
        },
        {
          label: "Desativado",
          value: "Desativado",
        },
      ],
      swatches: [
        "#DDDDDD",
        "#222222",
        "#f00000",
        "#be4178",
        "#f6b406",
        "#5bcb95",
      ],
    };
  },
  beforeMount() {
    this.user.filters.status.selected = "1";
    this.user.pagination.perPage = 500;
    this.getUsers();
    this.opportunitiesFunnelSteps.filters.funnelId = this.funnelId;
    this.opportunitiesFunnelSteps.filters.status.selected = ["Ativado"];
    this.getOpportunitiesFunnelSteps();
  },
  async mounted() {
    await this.getOpportunityFunnelStep(this.editId);
    await this.setOpportunityFunnelStepFieldsValue();
  },
  methods: {
    setOpportunityFunnelStepFieldsValue() {
      setTimeout(() => {
        this.form.setFieldsValue({
          stagnation_step:
            this.opportunitiesFunnelSteps.details.stagnation_step || undefined,
        });
      }, 400);
    },
    listUsers(listUsers) {
      return this.noneOption.concat(listUsers);
    },
    mapStepsList(arr) {
      return arr.map(({ name }) => {
        return {
          label: name,
          value: name,
        };
      });
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        values.id = this.editId;
        values.funnel_id = this.opportunitiesFunnelSteps.details.raw.funnel_id;
        console.log(
          err,
          values,
          values.status,
          this.opportunitiesFunnelSteps.details.status
        );
        if (!err) {
          this.loadingUpdateFunnelStep = true;

          values.color = this.funnelStepColor;

          if (
            this.opportunitiesFunnelSteps.details.status !== values.status &&
            values.status === "Desativado"
          ) {
            this.$http
              .get(
                `/opportunity/listV2?page=1&per_page=9999&step=${values.name}&order=desc&order-by=created`
              )
              .then(({ data }) => {
                data;
              });
            console.log("pergunta se vai mandar os opt");
          }

          this.$http
            .post("/opportunities-funnel-steps/update", values)
            .then(({ data }) => {
              this.$message.success(data.message);
              this.loadingUpdateFunnelStep = false;

              this.$http.post("/log/create", {
                user_id: this.$store.state.userData.id,
                module_id: this.editId,
                module: "opportunities-funnel-steps",
                action: "update-opportunity-funnel-step",
                description: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} atualizou a etapa ID ${this.editId}.`,
              });
            })
            .catch(({ response }) => {
              this.$message.error(response.data.message);
              this.loadingUpdateFunnelStep = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="sass">
.vue-swatches__swatch
  margin-bottom: 0 !important
.ant-form-item-control
  line-height: 23px !important
.vue-swatches__trigger
  width: 185px !important
  height: 28px !important
  border-radius: 4px !important
</style>
